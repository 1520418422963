import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import {Routes, Route} from 'react-router-dom'
import { message, Spin } from 'antd';
import Home from "./pages/Home";
import Main from "./components/layout/Main";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import SignIn from './pages/SignIn';
import axios from 'axios';
import LoadingPage from './pages/LoadingPage';
import { AuthContext } from './context/AuthContext';
const SupplierInput = lazy(() => import('./pages/supplierInput/SupplierInput'));
const SupplierInputForm = lazy(() => import('./pages/supplierInput/SupplierInputForm2'));
const Process = lazy(() => import('./pages/process/Process'));
const ProcessForm = lazy(() => import('./pages/process/ProcessForm'));
const Shipments = lazy(() => import('./pages/shipments/Shipments'));
const ShipmentsForm = lazy(() => import('./pages/shipments/ShipmentsForm'));
const CompletedShipment = lazy(() => import('./pages/shipments/CompletedShipment'));
const Permission = lazy(() => import('./pages/permission/Permission'));
const PermissionForm = lazy(() => import('./pages/permission/PermissionForm'));

function App() {
const [isAuthenticated, setIsAuthenticated] =useState(false)
const [loading, setLoading] = useState(false)

  const {setUserPermissions} = useContext(AuthContext)

  useEffect(()=>{
    const user =JSON.parse(localStorage.getItem('user'))

    if(user){
      const token = user.token;
      setLoading(true)
      axios.post('https://vimpex-principals.vercel.app/api/auth/profile', {token}).then((res)=>{
        setLoading(false)
        setIsAuthenticated(true)
        setUserPermissions(res.data);
      }).catch((err)=>{
        setLoading(false)
        message.error("Unauthorized action")
      })
    }
    
  },[])
 

  return (
    <div className="App">

        {isAuthenticated ?
        <Main>
          <Suspense fallback={<div style={{textAlign:'center',}}><Spin/></div>}>
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/supplierinput" element={<SupplierInput/>} />
            <Route exact path="/supplierinputform" element={<SupplierInputForm/>} />
            <Route exact path="/process" element={<Process/>} />
            <Route exact path="/processform" element={<ProcessForm/>} />
            <Route exact path="/shipments" element={<Shipments/>} />
            <Route exact path="/shipmentsform" element={<ShipmentsForm/>} /> 
            <Route exact path="/supplierinputform/:id" element={<SupplierInputForm/>} />
            <Route exact path="/processform/:id" element={<ProcessForm/>} />
            <Route exact path="/shipmentsform/:id" element={<ShipmentsForm/>} />
            <Route exact path="/completedshipments" element={<CompletedShipment/>} />
            <Route exact path="/permission" element={<Permission/>} />
            <Route exact path="/permissionform" element={<PermissionForm/>} />
            <Route exact path="/permissionform/:id" element={<PermissionForm/>} />
          </Routes>
          </Suspense>

        </Main>
        :
        loading ? <LoadingPage/> :
        <SignIn/>
        }

    </div>
  );
}

export default App;
